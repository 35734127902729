<script setup lang="ts">
import { format, parse } from 'date-fns'
import { MediaImageFragment, TeaserSliderFieldsFragment, TeaserSliderItemFieldsFragment } from '~/api/graphql/generated'

const props = defineProps<{
  entity: TeaserSliderFieldsFragment
}>()

const data = computed(() => ({
  title: props.entity.fieldTitle || '',
  subTitle: props.entity.fieldDescription?.processed || '',
  teasers:
    props.entity.fieldItems
      ?.map((item) => item?.entity as TeaserSliderItemFieldsFragment)
      .map((item) => {
        return {
          variant: item.fieldStyle ?? 'style_2',
          image: item.fieldImage?.entity as MediaImageFragment,
          title: item.fieldTitle || '',
          type: item.fieldType?.entity?.name || '',
          date: item.fieldDate?.date
            ? format(parse(item.fieldDate?.date, "yyyy-MM-dd HH:mm:ss 'UTC'", new Date()), 'dd.MM.yyyy')
            : '',
          description: item.fieldDescription?.processed || '',
          link: {
            url: item.fieldLink?.url?.path || '',
            target: item.fieldLink?.options?.attributes.target || '',
            text: item.fieldLink?.title || '',
          },
        }
      }) || [],
  link: {
    url: props.entity.fieldLink?.url?.path || '',
    target: props.entity.fieldLink?.options?.attributes.target || '_self',
    text: props.entity.fieldLink?.title || '',
  },
}))
</script>

<template>
  <TeaserSlider v-bind="data" />
</template>
